import React from "react"
import Layout from "../components/layout"
// import "react-responsive-carousel/lib/styles/carousel.min.css"
import TechnicalData from "../components/product_tech_data"
import ProductDetail from "../components/product_detail"
import nala_cleaning from "../../public/static/images/products/nala_cleaning.jpg"

function NalaCleaning() {

  const prodDetail = {
    "name": "NALA CLEANING / DESILTING MACHINE",
    "detail": {
      "about" : ["We are Manufacturing highly productive Nala Cleaning Machine that are used to providing the cleaning facility of open Nala & other waste yard. We are Manufactured with high Quality Materials, reliability, superior hydraulic system & utilization for its end use."],
      "advantages": [
        "One System can easily handle Lifting, Shifting & Dumping during working hours.",
        "It facilitates safe and hygienic collection, storage and larger volume garbage transportation.",
        "Fast Operation.",
        "This Machine is made available in various sizes and shapes. Clients can opt for different dimensions according to the specific business need. Machine offered by us has gained high appreciation among our clients."
      ]
    },
    "tech_data": {
      "column1": [
        "Code",
        "Body Size",
        "Sheet Material",
        "Thickness",
        "Hydraulic Cylinder",
        "Axle & Wheels",
        "Hydraulic Pump",
        "Hydraulic Pipes",
        "PTO Gear Box",
        "Arrangement"
      ],
      "column2": [
        "IESCOOP - LC / LT",
        "Tractor Trailer mounted or Customized.",
        "Rectangle with 3Cum,4Cum,5CumEtc.",
        "Mild Steel Sheets IS 2062 Grade - A Etc.",
        "2 mm, 2.5 mm, 3 mm, 5 mm or can be Customized as per requirement",
        "2 Nos. for Booms, 1 Nos. For Bucket , 2 Nos. For Stabilizers or can be Customized.",
        "Heavy Duty Rigid Axle with 1 Nos. of 2 nos. Wheel",
        "40-80 LPM",
        "High Pressure Hoses or Pipelines Provides",
        "Suitable Power to drive system",
        "Fully Hydraulic system provides the optimum cleaning of Nala and Dump yard by help of Hydraulic system operated by Control Valves to minimize the Human efforts & wastes. Highly Suitable for Municipalities for Solid & Liquid waste handling."
      ]
    }
  }

  return (
    <Layout className="semi-automatic">
      <ProductDetail name={prodDetail.name} detail={prodDetail.detail} image={nala_cleaning} />

      {prodDetail.tech_data !== undefined ? <TechnicalData techData={prodDetail.tech_data} /> : <></>}
    </Layout>
  )
}

export default NalaCleaning
